<template>
    <div v-if="person">
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="d-flex align-center justify-space-between">
                    <h1 class="text-h4">{{ person.fulltNavn }}</h1>
                </v-col>

                <v-col cols="12">
                    <v-tabs v-model="tab" class="v-tabs--content">
                        <v-tab>Generelt</v-tab>
                        <v-tab v-if="hasPersonRole('BRUKER_SORG')">Sorgtjenesten</v-tab>
                        <v-tab v-if="hasPersonRole('BRUKER_HJEMME') || hasPersonRole('FRIVILLIG_HJEMME')">Hjemmetjeneste</v-tab>
                        <v-tab v-if="hasPersonRole('FRIVILLIG') || hasPersonRole('FRIVILLIGIPROSESS')">Frivillig</v-tab>
                        <v-tab>Personalia</v-tab>
                        <v-tab v-if="hasUserRole('ANSATT')">Kommentarer</v-tab>
                        <v-tab>Samtykke</v-tab>
                    </v-tabs>
                    <br />
                    <v-tabs-items v-model="tab">
                        <v-tab-item><tab-general v-model="person" v-on:updated="updated" v-on:close="close"></tab-general> </v-tab-item>
                        <v-tab-item v-if="hasPersonRole('BRUKER_SORG')">
                            <tab-grief-service v-model="person"></tab-grief-service>
                        </v-tab-item>
                        <v-tab-item v-if="hasPersonRole('BRUKER_HJEMME') || hasPersonRole('FRIVILLIG_HJEMME')">
                            <tab-visit-service v-model="person"></tab-visit-service>
                        </v-tab-item>
                        <v-tab-item v-if="hasPersonRole('FRIVILLIG') || hasPersonRole('FRIVILLIGIPROSESS')">
                            <tab-volunteer v-model="person"></tab-volunteer>
                        </v-tab-item>
                        <v-tab-item>
                            <tab-personalia v-model="person"></tab-personalia>
                        </v-tab-item>
                        <v-tab-item v-if="hasUserRole('ANSATT')">
                            <tab-comments v-model="person" type="person"></tab-comments>
                        </v-tab-item>
                        <v-tab-item><tab-privacy v-model="person" v-on:updated="updated"></tab-privacy> </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TabGeneral from '@/pages/persons/TabGeneral.vue';
import TabPrivacy from '@/pages/persons/TabPrivacy.vue';
import TabPersonalia from '@/pages/persons/TabPersonalia.vue';
import TabVolunteer from '@/pages/persons/TabVolunteer.vue';
import TabGriefService from '@/pages/persons/TabGriefService.vue';
import TabVisitService from '@/pages/persons/TabVisitService.vue';
import TabComments from '@/components/TabComments.vue';

export default {
    name: 'Person',
    components: { TabGeneral, TabPrivacy, TabPersonalia, TabVolunteer, TabComments, TabGriefService, TabVisitService },
    props: {
        role: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            tab: 0,
            person: null,

            status: null,
            sluttet: null,

            roles: [
                { role: 'FRIVILLIG', label: 'Frivillig' },
                { role: 'BRUKER', label: 'Bruker' },
                { role: 'ANSATT', label: 'Ansatt' },
            ],

            statuses: [
                { status: 'AKTIV', label: 'Aktiv' },
                { status: 'PERMISJON', label: 'I permisjon' },
                { status: 'SLUTTET', label: 'Sluttet' },
            ],
        };
    },
    computed: {
        ...mapGetters('api', ['hasUserRole']),

        heading: function () {
            return this.$i18n.t('persons.' + this.role.toLowerCase() + '.new.heading');
        },
        newBtn: function () {
            return this.$i18n.t('persons.' + this.role.toLowerCase() + '.new.new');
        },
        newRoute: function () {
            return { path: '/personer/' + this.role.toLowerCase() + '/ny' };
        },
        /*personStatus: function () {
            return this.statuses[this.person.status];
        },*/
    },
    filters: {
        fulltNavn: function (item) {
            return item.fornavn + ' ' + item.etternavn;
        },
    },
    watch: {
        id: async function (value) {
            this.id = value;
            await this.load();
        },
    },
    created: async function () {
        await this.load();
        //this.tab = 0;
    },
    methods: {
        ...mapActions('api', ['request', 'formatPerson']),
        ...mapActions(['snackbar']),

        /**
         * load
         */
        load: async function () {
            if (this.id) {
                const response = await this.request({
                    method: 'get',
                    url: '/person/' + this.id,
                });

                if (response && typeof response.id != 'undefined') {
                    await this.update(response);
                }
            }
        },

        /**
         * terminate
         */
        terminate: async function () {
            if (this.sluttet) {
                const response = await this.request({
                    method: 'post',
                    url: '/person/' + this.id + '/sluttet/?sluttet=' + this.sluttet.format('DD.MM.YYYY'),
                });

                if (response && typeof response.id != 'undefined') {
                    await this.update(response);
                }
            }
        },

        /**
         * update
         */
        update: async function (person) {
            this.person = await this.formatPerson(person);
            this.status = person.status;
            this.sluttet = this.person.sluttet;
        },

        /**
         * updated
         */
        updated: function (person) {
            this.person = person;
        },

        /**
         * close
         */
        close: function (person) {
            this.$emit('close', person);
        },

        /**
         * hasPersonRole
         */
        hasPersonRole: function (rolle) {
            return this.person.roller && this.person.roller.filter((r) => r.id == rolle).length > 0;
        },
    },
};
</script>
<style lang="scss">
.v-tabs--content {
    .v-item-group {
        border-bottom: 3px solid #5582b0;
    }

    .v-tab--active {
        background-color: lighten(#5582b0, 45%) !important;
    }
}
</style>
