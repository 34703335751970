<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="frivilliginformasjon.tjeneste" label="Tjeneste"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="frivilliginformasjon.oppfølgingsansvarlig" label="Oppfølgingsansvarlig"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <date-field v-model="frivilliginformasjon.kontaktDato" label="Dato kontakt"></date-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <date-field v-model="frivilliginformasjon.inntakssamtaleDato" label="Inntakssamtale"></date-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <date-field v-model="frivilliginformasjon.samtykkeskjemaDato" label="Samtykkeskjema"></date-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <date-field v-model="frivilliginformasjon.politiattestDato" label="Politiattest"></date-field>
                    </v-col>
                </v-row>
            </v-form>
            <v-col cols="12" style="margin-top: -32px">
                <template v-for="(element, index) in undervisning">
                    <div v-if="element.overskrift" v-bind:key="index" class="mt-4 caption">{{ element.overskrift }}</div>
                    <v-checkbox
                        v-else-if="!element.skjult"
                        v-bind:key="index"
                        v-model="element.utfort"
                        v-on:change="valgtUndervisningsElement(element)"
                        color="primary"
                        :value="true"
                        :disabled="element.lesetilgang || false"
                        hide-details
                        style="margin-top: 0"
                    >
                        <template v-slot:label>
                            {{ element.navn }}
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-if="element.dato" :disabled="element.lesetilgang || false" text v-bind="attrs" v-on="on" class="ml-2">
                                        ({{ element.dato | format('DD.MM.YYYY') }})
                                    </v-btn>
                                    <v-btn v-else icon color="primary" text v-bind="attrs" v-on="on" class="ml-2">
                                        <v-icon>mdi-calendar</v-icon>
                                    </v-btn>
                                </template>
                                <v-date-picker v-model="element.valgtDato" v-on:input="undervisningsTimeDatoEndret(element)"></v-date-picker>
                            </v-menu>
                        </template>
                    </v-checkbox>
                    <a
                        v-if="!element.overskrift && !element.skjult && visFlereUndervisningsElementer(element, index)"
                        v-on:click="visUndervisningsElementer(index)"
                        v-bind:key="'more-' + index"
                        class="my-2 ml-8"
                    >
                        Vis tidligere år...
                    </a>
                </template>
            </v-col>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="endret || endretUndervisning" color="primary" v-on:click="save">Lagre</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import DateField from '@/vendor/noop/components/DateField.vue';

export default {
    name: '',
    components: {
        DateField,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        const frivilliginformasjon = {
            tjeneste: null,
            kontaktDato: null,
            inntakssamtaleDato: null,
            samtykkeskjemaDato: null,
            politiattestDato: null,
            oppfølgingsansvarlig: null,
            kommentar: null,
        };

        const undervisning = [
            { overskrift: 'Opplæring', type: 'GRUNNKURS' },
            {
                type: 'GRUNNKURS',
                navn: 'Grunnkurs 1',
                utfort: null,
                dato: null,
            },
            {
                type: 'GRUNNKURS',
                navn: 'Grunnkurs 2',
                utfort: null,
                dato: null,
            },
            {
                type: 'SORGKURS',
                navn: 'Sorgkurs 1',
                utfort: null,
                dato: null,
            },
            {
                type: 'SORGKURS',
                navn: 'Sorgkurs 2',
                utfort: null,
                dato: null,
            },
            { overskrift: 'Veiledning', type: 'VEILEDNING' },
            /*{
                type: 'VEILEDNING',
                navn: 'Veiledning',
                utfort: null,
                dato: null,
            },*/
            { overskrift: 'Oppfølgingssamtaler', type: 'OPPFOLGINGSAMTALE' },
            /*
            {
                type: 'OPPFOLGINGSAMTALE',
                navn: 'Oppfølgingssamtale',
                utfort: null,
                dato: null,
            },*/
        ];

        return {
            readonly: false,
            endret: false,
            endretUndervisning: false,

            person: {},

            frivilliginformasjon: frivilliginformasjon,
            ingenFrivilliginformasjon: Object.assign({}, frivilliginformasjon),

            undervisning: [],
            undervisningMal: undervisning,
        };
    },
    filters: {
        format: function (value, format) {
            if (typeof value == 'string') {
                value = moment(value, 'YYYY-MM-DD');
            }
            return value.format(format);
        },
    },
    watch: {
        value: async function (value) {
            this.person = this.value = value;
            await this.update();
        },
        frivilliginformasjon: {
            handler: function () {
                this.endret = true;
            },
            deep: true,
        },
    },

    /**
     * created
     */
    created: function () {
        if (this.value) {
            this.person = this.value;
            this.update();
        }
    },
    methods: {
        ...mapActions('api', ['request', 'formatPerson']),
        ...mapActions(['snackbar']),

        valgtUndervisningsElement: function (element) {
            if (!element.utfort) {
                element.dato = null;
            }
            element.endret = true;
            this.endretUndervisning = true;
        },

        undervisningsTimeDatoEndret: function (element) {
            if (element.valgtDato) {
                element.dato = moment(element.valgtDato, 'YYYY-MM-DD');
                element.utfort = true;
            }
            element.endret = true;
            this.endretUndervisning = true;
        },

        visFlereUndervisningsElementer: function (time, index) {
            if (this.undervisning.length > index + 1) {
                if (this.undervisning[index + 1].skjult) {
                    return true;
                }
            }
            return false;
        },

        visUndervisningsElementer: function (index) {
            for (let i = index + 1; i < this.undervisning.length; i++) {
                if (this.undervisning[i].skjult) {
                    this.undervisning[i].skjult = false;
                } else {
                    break;
                }
            }
            return false;
        },

        /**
         * update
         */
        update: function () {
            if (this.person && this.person.frivilliginformasjon) {
                for (const field in this.frivilliginformasjon) {
                    this.frivilliginformasjon[field] = this.person.frivilliginformasjon[field];
                }
            } else {
                for (const field in this.frivilliginformasjon) {
                    this.frivilliginformasjon[field] = this.ingenFrivilliginformasjon[field];
                }
            }

            const undervisning = [];
            for (const element of this.undervisningMal) {
                if (element.overskrift) {
                    if (element.type == 'VEILEDNING' || element.type == 'OPPFOLGINGSAMTALE') {
                        if (this.hasPersonRole('FRIVILLIG')) {
                            undervisning.push(Object.assign({}, element));

                            const elementer = [];
                            const iAar = moment().year();
                            let antallIaar = 0;
                            if (this.person.undervisning) {
                                for (const personElement of this.person.undervisning) {
                                    if (personElement.type == element.type) {
                                        let skjult = false;
                                        const aar = personElement.dato ? personElement.dato.year() : null;
                                        if (!aar || aar == iAar) {
                                            antallIaar++;
                                        } else if (aar < iAar - 1) {
                                            skjult = true;
                                        }

                                        personElement.valgtDato = personElement.dato ? personElement.dato.format('YYYY-MM-DD') : null;
                                        elementer.push(Object.assign({ endret: false, skjult: skjult }, personElement));
                                    }
                                }
                            }

                            for (let i = Math.min(1, antallIaar); i < 2; i++) {
                                elementer.unshift({
                                    type: element.type,
                                    navn: element.type == 'VEILEDNING' ? 'Veiledning' : 'Oppfølgingssamtale',
                                    utfort: null,
                                    dato: null,
                                });
                            }
                            undervisning.push(...elementer);
                        }
                    } else {
                        undervisning.push(Object.assign({}, element));
                    }
                } else {
                    if (element.type == 'GRUNNKURS' || element.type == 'SORGKURS') {
                        if (this.person.undervisning) {
                            let funnet = false;
                            for (const personElement of this.person.undervisning) {
                                if (personElement.type == element.type && personElement.navn == element.navn) {
                                    personElement.valgtDato = personElement.dato ? personElement.dato.format('YYYY-MM-DD') : null;
                                    undervisning.push(Object.assign({ endret: false }, personElement));
                                    funnet = true;
                                    break;
                                }
                            }
                            if (!funnet) {
                                undervisning.push(Object.assign({ endret: false, valgtDato: null }, element));
                            }
                        } else {
                            undervisning.push(Object.assign({}, element));
                        }
                    }
                }
            }
            this.undervisning = undervisning;

            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            window.setTimeout(() => {
                this.endret = false;
            }, 100);
        },

        /**
         * save
         */
        save: async function () {
            if (!this.$refs.form.validate()) {
                console.log('TODO: error message');
            } else {
                try {
                    let person = null;

                    if (this.endret) {
                        const data = Object.assign({}, this.frivilliginformasjon);
                        for (const field of ['kontaktDato', 'inntakssamtaleDato', 'samtykkeskjemaDato', 'politiattestDato']) {
                            if (data[field]) {
                                data[field] = data[field].format('DD.MM.YYYY');
                            }
                        }

                        const response = await this.request({
                            method: 'post',
                            url: '/person/' + this.person.id + '/frivilliginformasjon',
                            data: data,
                        });

                        if (response && typeof response.id != 'undefined') {
                            person = response;
                        }
                    }

                    if (this.endretUndervisning) {
                        for (const element of this.undervisning) {
                            if (element.endret === true) {
                                const data = new FormData();
                                data.append('navn', element.navn);
                                data.append('type', element.type);
                                data.append('utfort', element.utfort || false);
                                if (element.dato) {
                                    data.append('dato', element.dato.format('DD.MM.YYYY'));
                                }

                                let response = null;

                                if (element.id) {
                                    response = await this.request({
                                        method: 'put',
                                        url: '/person/' + this.person.id + '/undervisning/' + element.id,
                                        data: data,
                                    });
                                } else {
                                    response = await this.request({
                                        method: 'post',
                                        url: '/person/' + this.person.id + '/undervisning',
                                        data: data,
                                    });
                                }

                                if (response && typeof response.id != 'undefined') {
                                    person = response;
                                }
                            }
                        }
                    }

                    // success
                    if (person) {
                        this.person = await this.formatPerson(person);
                        this.update();

                        this.$emit('input', this.person);

                        this.snackbar('Lagret');
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },

        /**
         * cancel
         */
        cancel: function () {
            this.update();
            this.endret = false;
            this.endretUndervisning = false;
        },

        /**
         * hasPersonRole
         */
        hasPersonRole: function (rolle) {
            return this.person.roller && this.person.roller.filter((r) => r.id == rolle).length > 0;
        },
    },
};
</script>
<style lang="scss"></style>
